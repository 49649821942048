import React from "react"
import Header from "./header/header"
import "./layout.css"
import { ProgressBar } from "scrolling-based-progressbar"

const Layout = ({ children }) => {
  if (!children) return null;
  return (
    <React.Fragment>
      <ProgressBar height="4px" color="#6D7FCC" />
    
            <Header className="header row-fluid"/>
            <div className='main-container'>
           {children}
            </div>
    </React.Fragment>
  )
}

export default Layout
