import React from "react"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faMarker,
  faPencilRuler,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons"
import {
  faGithub,
  faHackerrank,
  faLinkedin,
  faStackOverflow,
} from "@fortawesome/free-brands-svg-icons"
import "./header.styles.css"
import Divider from "@material-ui/core/Divider"
import imgData from "../../images/itcahi.jpg"

class Header extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!-- Sidebar  --> */}
        <nav id="sidebar">
          <div className="sidebar-header text-center">
              <>
                <img
                  src={imgData}
                  width="120"
                  height="110"
                  className="ml-2 border rounded-circle showOnMView"
                  alt="author"
                />
                <br />
                <strong className='showOnMView'>Vikash Chander</strong>
              </>
              <h6 className="hideName">VC</h6>
          </div>
          <ul className="list-unstyled">
            <li>
              <Link to="/about"  activeClassName="navbar-element" className="remove-highlight">
                <FontAwesomeIcon icon={faHome} className="mx-2" />
                <span className="paraIcon">About Me</span>
              </Link>
            </li>
            <li>
              <Link
                to="/project"
                activeClassName="navbar-element"
                className="remove-highlight"
              >
                {" "}
                <FontAwesomeIcon icon={faPencilRuler} className="mx-2" />
                <span className=" paraIcon"> Project</span>
              </Link>
            </li>
            <li>
              <Link
                to="/blog"
                partiallyActive={true}
                activeClassName="navbar-element"
                className="remove-highlight"
              >
                <FontAwesomeIcon icon={faMarker} className="mx-2" />
                <span className=" paraIcon"> My Blogs</span>
              </Link>
            </li>
            <li>
              <Link to="/work"  activeClassName="navbar-element" className="remove-highlight">
                <FontAwesomeIcon icon={faTerminal} className="mx-2" />
                <span className=" paraIcon">
                  Work Experience
                </span>
              </Link>
            </li>
          </ul>
          <Divider/>
          <ul className="list-unstyled">
            <li>
              <a
                href="https://github.com/vikashchander"
                target="_blank"
                rel="noopener noreferrer"
                className="remove-highlight"
              >
                <FontAwesomeIcon icon={faGithub} className="mx-2" />
                <span className=" paraIcon"> Github</span>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/vikashchander/"
                target="_blank"
                rel="noopener noreferrer"
                className="remove-highlight"
              >
                <FontAwesomeIcon icon={faLinkedin} className="mx-2" />
                <span className=" paraIcon">Linkedin</span>
              </a>
            </li>
            <li>
              <a
                href="https://stackoverflow.com/users/12160909/vikash-chander?tab=profile"
                target="_blank"
                rel="noopener noreferrer"
                className="remove-highlight"
              >
                <FontAwesomeIcon icon={faStackOverflow} className="mx-2" />
                <span className="paraIcon">
                  {" "}
                  StackOverflow
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.hackerrank.com/vikashchander"
                target="_blank"
                rel="noopener noreferrer"
                className="remove-highlight"
              >
                <FontAwesomeIcon icon={faHackerrank} className="mx-2" />
                <span className="paraIcon">
                  {" "}
                  HackerRanker
                </span>
              </a>
            </li>
          </ul>
        </nav>
      </React.Fragment>
    )
  }
}

export default Header;
